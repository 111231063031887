import React, { useEffect, useState } from 'react';
import { Button, Modal, Form, Tabs, Tab, Accordion } from 'react-bootstrap';
import axios from '../../../../constents/axios';
import styles from '../../../../styles/editFull.module.css';
import { GrChapterAdd } from "react-icons/gr";
import { CiSquareRemove } from "react-icons/ci";

const EditMaterial = ({ show, onHide, courseName, currentTopicName, currentSubTopicName, currentMaterialId, currentMaterialType, chapters, onSuccess }) => {
  const [newMaterialId, setNewMaterialId] = useState(currentMaterialId);
  const [newMaterialCaption, setNewMaterialCaption] = useState('');
  const [newMaterialLink, setNewMaterialLink] = useState('');
  const [newMaterialType, setNewMaterialType] = useState(currentMaterialType);
  const [newChapters, setNewChapters] = useState(chapters || []);
  const [currentMaterialCaption, setCurrentMaterialCaption] = useState("");
  const [currentQuestionData, setCurrentQuestionData] = useState([]);

  const fetchMaterialDetails = async () => {
    try {
      const response = await axios.get('/get-material-details', {
        params: {
          currentMaterialId,
          currentMaterialType
        }
      });
      const { caption, std, video } = response.data;
      setNewMaterialCaption(caption);
      setCurrentMaterialCaption(caption);
      if (currentMaterialType === "pdf") {
        setNewMaterialLink(video);
      } else if (currentMaterialType === "video") {
        setNewMaterialLink(std);
        setNewChapters(chapters);
      }
    } catch (error) {
      console.error('Error fetching material details:', error);
    }
  };

  const handleFullEditMaterial = async (e) => {
    e.preventDefault();
    if (!newMaterialId || !newMaterialCaption || !newMaterialLink) {
      console.error('All fields are required.');
      return;
    }

    try {
      const response = await axios.put('/edit-full-material', {
        course_id: courseName,
        currentTopic: currentTopicName,
        currentSubTopic: currentSubTopicName,
        currentMaterialId,
        currentMaterialType,
        currentMaterialCaption,
        newMaterialId,
        newMaterialCaption,
        newMaterialLink,
        chapters: newChapters,
      });
      onSuccess();
      onHide();
    } catch (error) {
      console.error('Error updating material:', error);
    }
  };

  const handleEditMaterial = async () => {
    if (!newMaterialId) {
      console.error('Both Material ID and Material Type are required.');
      return;
    }

    try {
      const response = await axios.put(`/edit-material`, {
        course_id: courseName,
        currentTopic: currentTopicName,
        currentSubTopic: currentSubTopicName,
        currentMaterialId: currentMaterialId,
        currentMaterialCaption: currentMaterialCaption,
        currentMaterialType: currentMaterialType,
        newMaterialId: newMaterialId,
      });
      onSuccess();
      onHide();
    } catch (error) {
      console.error('Error updating material:', error);
    }
  };

  const handleAddChapter = () => {
    setNewChapters([...newChapters, { title: '', start: '' }]);
  };

  const handleRemoveChapter = (index) => {
    const updatedChapters = [...newChapters];
    updatedChapters.splice(index, 1);
    setNewChapters(updatedChapters);
  };

  const handleInputChange = (index, event) => {
    const { name, value } = event.target;
    const updatedQuestions = [...currentQuestionData];
    if (name === "time_in_minute") {
      updatedQuestions[index] = {
        ...updatedQuestions[index],
        original_time: value,
      };
      const [hours, minutes] = value.split(':').map(Number);
      const totalMinutes = (hours || 0) * 60 + (minutes || 0);
      updatedQuestions[index] = {
        ...updatedQuestions[index],
        time_in_minute: totalMinutes,
      };
    } else {
      updatedQuestions[index] = {
        ...updatedQuestions[index],
        [name]: value,
      };
    }
    setCurrentQuestionData(updatedQuestions);
  };



  const handleInputChangeChapter = (index, event) => {
    const { name, value } = event.target;
    const updatedChapters = [...newChapters];
    updatedChapters[index] = {
      ...updatedChapters[index],
      [name]: value
    };
    setNewChapters(updatedChapters);
  };

  const handleRemoveQuestion = (index) => {
    const updatedQuestions = [...currentQuestionData];
    updatedQuestions.splice(index, 1);
    setCurrentQuestionData(updatedQuestions);
  };

  const handleAddQuestion = () => {
    setCurrentQuestionData([
      ...currentQuestionData,
      {
        question: '',
        option_1: '',
        option_2: '',
        option_3: '',
        option_4: '',
        right_answer: '',
        explanation: '',
        time_in_minute: ''
      }
    ]);
  };


  const handleEditQuestion = async () => {
    try {
      const response = await axios.post('/update-questions', {
        course_id: courseName,
        video_id: currentMaterialId,
        questions: currentQuestionData
      });
      onSuccess();
      onHide();
    } catch (error) {
      console.error('Error updating questions:', error);
    }
  };

  useEffect(() => {
    setNewMaterialId(currentMaterialId);
    setNewMaterialType(currentMaterialType);
    fetchMaterialDetails();
    const fetchQuestions = async () => {
      if (!currentMaterialId) {
        console.error('Material ID is required.');
        return;
      }
      try {
        const response = await axios.post('/show-questions', {
          course_id: courseName,
          video_id: currentMaterialId,
        });
        setCurrentQuestionData(response.data);
      } catch (error) {
        console.error('Error retrieving questions:', error);
      }
    };

    fetchQuestions();
  }, [courseName, currentMaterialId, onSuccess, onHide, currentMaterialId, currentMaterialType]);


  return (
    <Modal show={show} size="md" backdrop="static" onHide={onHide}>
      <Modal.Header closeButton></Modal.Header>
      <Tabs defaultActiveKey="home" transition={true} id="noanim-tab-example" className="mb-3">
        <Tab eventKey="home" title="Edit Material">
          <Form onSubmit={handleEditMaterial}>
            <Modal.Body>
              <Form.Control
                size="sm"
                required
                onChange={(e) => setNewMaterialId(e.target.value)}
                type="number"
                placeholder="New Material ID"
                value={newMaterialId}
              />
              <br />
            </Modal.Body>
            <Modal.Footer>
              <Button variant="outline-primary" type="submit">Update</Button>
            </Modal.Footer>
          </Form>
        </Tab>
        <Tab eventKey="profile" title="Edit Full Material">
          <Form onSubmit={handleFullEditMaterial}>
            <Modal.Body>
              <div className={styles.inputDiv}>
                <label>Caption</label>
                <Form.Control
                  size="sm"
                  required
                  onChange={(e) => setNewMaterialCaption(e.target.value)}
                  type="text"
                  placeholder="New Material Caption"
                  value={newMaterialCaption}
                />
              </div>
              <div className={styles.inputDiv}>
                <label>Link</label>
                <Form.Control
                  size="sm"
                  required
                  onChange={(e) => setNewMaterialLink(e.target.value)}
                  type="text"
                  placeholder="New Material Link"
                  value={newMaterialLink}
                />
              </div>
              {currentMaterialType && currentMaterialType === "video" && (
                <>
                  {Array.isArray(newChapters) && newChapters.length > 0 && ( // Check if newChapters is an array
                    <div className={styles.inputDiv}>
                      <label>Chapter Times & Titles</label>
                      {newChapters.map((chapter, index) => (
                        <div key={index} className={styles.chapterRow}>
                          <Form.Control
                            type="text"
                            name="title"
                            value={chapter.title}
                            onChange={(event) => handleInputChangeChapter(index, event)}
                            placeholder="Chapter Title"
                          />
                          <Form.Control
                            type="time"
                            name="start"
                            step="1"
                            value={chapter.start}
                            onChange={(event) => handleInputChangeChapter(index, event)}
                            placeholder="Start Time"
                          />
                          <Button
                            variant="outline-danger"
                            size="sm"
                            title="Remove Chapter"
                            onClick={() => handleRemoveChapter(index)}
                          >
                            <CiSquareRemove className="fs-5" />
                          </Button>
                        </div>
                      ))}
                    </div>
                  )}
                  <Button
                    variant="outline-warning"
                    size="sm"
                    title="Add Chapter"
                    onClick={handleAddChapter}
                  >
                    <GrChapterAdd /> Add Chapter
                  </Button>
                  <br />
                  <br />
                  <Accordion>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>Edit Questions</Accordion.Header>
                      <Accordion.Body>
                        {Array.isArray(currentQuestionData) && currentQuestionData.length > 0 && ( // Check if currentQuestionData is an array
                          <div className={styles.editQuestion}>
                            <div className="row">
                              {currentQuestionData.map((question, index) => (
                                <div key={index} className={styles.questionRow}>
                                  <h6>Qs No: {index + 1}</h6>
                                  <label htmlFor={`question_${index}`}>Question</label>
                                  <Form.Control
                                    as="textarea"
                                    rows={3}
                                    name="question"
                                    value={question.question}
                                    onChange={(event) => handleInputChange(index, event)}
                                    placeholder="Question"
                                  />
                                  <label htmlFor={`option_1_${index}`}>Options</label>
                                  <div className="row">
                                    {["option_1", "option_2", "option_3", "option_4"].map((option, idx) => (
                                      <div className="col-md-3" key={option}>
                                        <Form.Control
                                          type="text"
                                          name={option}
                                          value={question[option]}
                                          onChange={(event) => handleInputChange(index, event)}
                                          placeholder={`Option ${idx + 1}`}
                                        />
                                      </div>
                                    ))}
                                  </div>
                                  <label htmlFor={`right_answer_${index}`}>Right Answer</label>
                                  <Form.Control
                                    type="text"
                                    name="right_answer"
                                    value={question.right_answer}
                                    onChange={(event) => {
                                      const value = event.target.value;
                                      // Allow only digits from 1 to 4
                                      if (/^[1-4]?$/.test(value)) {
                                        handleInputChange(index, event);
                                      }
                                    }}
                                    placeholder="Correct Answer"
                                    maxLength="1" // Limit input to 1 character
                                  />
                                  <label htmlFor={`explanation_${index}`}>Explanation</label>
                                  <Form.Control
                                    as="textarea"
                                    rows={3}
                                    name="explanation"
                                    value={question.explanation}
                                    onChange={(event) => handleInputChange(index, event)}
                                    placeholder="Explanation"
                                  />
                                  <label htmlFor={`time_in_minute_${index}`}>Time in Minute</label>
                                  <Form.Control
                                    type="time"
                                    step="1"
                                    name="time_in_minute"
                                    value={question.original_time}
                                    onChange={(event) => handleInputChange(index, event)}
                                    placeholder="Question Time"
                                  />
                                  <br />
                                  <div className={styles.placeEnd}>
                                    <Button
                                      variant="outline-danger"
                                      size="sm"
                                      title="Remove Question"
                                      onClick={() => handleRemoveQuestion(index)}
                                    >
                                      <CiSquareRemove />
                                    </Button>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        )}
                        <div className={styles.placeEnd}>
                          <Button
                            variant="outline-success"
                            size="sm"
                            title="Add Question"
                            onClick={handleAddQuestion}
                          >
                            Add Question
                          </Button>
                        </div>
                        <br />
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                          <Button
                            variant="outline-warning"
                            size="sm"
                            title="Edit Question"
                            onClick={handleEditQuestion}
                          >
                            Save Changes in Questions
                          </Button>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </>
              )}

            </Modal.Body>
            <Modal.Footer>
              <Button variant="light" title='Cancel' onClick={onHide}>Cancel</Button>
              <Button variant="outline-primary" title='Update Full Material' type="submit">Update</Button>
            </Modal.Footer>
          </Form>
        </Tab>
      </Tabs>
    </Modal>
  );
};

export default EditMaterial;
