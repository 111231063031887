import React, { useState, useEffect } from 'react';
import styles from '../../styles/sidebar.module.css';
import {
  CDBSidebar,
  CDBSidebarContent,
  CDBSidebarHeader,
  CDBSidebarMenu,
  CDBSidebarMenuItem,
  CDBSidebarFooter,
} from 'cdbreact';
import { Link, Outlet } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap';
import Login from '../Login/Login';
import userImage from './user.png'
import axios from '../../constents/axios';

const SideBar = ({ user, setUser }) => {
  const [showModal, setShowModal] = useState(false);
  const [isLoggingOut, setIsLoggingOut] = useState(false);

  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);

  const handleLogOut = () => {
    setIsLoggingOut(true);
  };

  const handleTranscode = async () => {
    try {
      await axios.get(
        '/transcode',
      );
    } catch (error) {
      console.error('Error starting transcoding server:', error.message);
    }
  };
  

  useEffect(() => {
    if (isLoggingOut) {
      localStorage.removeItem('adminLogin');
      setUser(null);
      setShowModal(false);
      setIsLoggingOut(false);
    }
  }, [isLoggingOut]);

  return (
    <div className={styles.sidebarDiv}>
      <CDBSidebar className={styles.sidebar}>
        <CDBSidebarHeader prefix={<i className="fa fa-bars" />}>
          Admin Panel
        </CDBSidebarHeader>
        <CDBSidebarContent className={styles.menuBar}>
          <CDBSidebarMenu className={styles.menuItem}>
            <Link to="/studentJourney" className={styles.link}>
              <CDBSidebarMenuItem className={styles.item} icon="th-large">
                Student Journey
              </CDBSidebarMenuItem>
            </Link>
            <Link to="/e-books" className={styles.link}>
              <CDBSidebarMenuItem className={styles.item} icon="book">
                E-Books
              </CDBSidebarMenuItem>
            </Link>
            <Link to="/convertStudentJourney" className={styles.link}>
              <CDBSidebarMenuItem className={styles.item} icon="sync">
                Convertion
              </CDBSidebarMenuItem>
            </Link>
            <Link to="/reels" className={styles.link}>
              <CDBSidebarMenuItem className={styles.item} icon="play">
                Reels
              </CDBSidebarMenuItem>
            </Link>
            {/* <Link to="/addlivevideo" className={styles.link}>
              <CDBSidebarMenuItem className={styles.item} icon="th-large">
                Add Live Video
              </CDBSidebarMenuItem>
            </Link>
            <Link to="/livevideo" className={styles.link}>
              <CDBSidebarMenuItem className={styles.item} icon="th-large">
                Live Video
              </CDBSidebarMenuItem>
            </Link> */}
          </CDBSidebarMenu>
        </CDBSidebarContent>
        <CDBSidebarFooter className={styles.footer} style={{ textAlign: 'center' }}>
            <Link className={styles.link} onClick={handleTranscode}>
              <CDBSidebarMenuItem className={styles.item} icon="sync">
                Start Transcode
              </CDBSidebarMenuItem>
            </Link>
          {user && (
            <CDBSidebarMenu className={styles.menuItem}>
              <CDBSidebarMenuItem className={styles.item} icon="user" onClick={handleShow}>
                <strong>{user.name}</strong>
              </CDBSidebarMenuItem>
            </CDBSidebarMenu>
          )}
        </CDBSidebarFooter>
      </CDBSidebar>
      <div className={styles.OutletDiv}>
        <div className="col py-3 p-0 m-0" style={{ flexGrow: '1' }}>
          <Outlet />
        </div>
      </div>

      {user ? (
        <Modal show={showModal} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Profile</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className='row'>
              <div className='col-md-3'>
                <div >
                  <img className={styles.userImageDiv} src={userImage} alt={user.name} />
                </div>
              </div>
              <div className='col-md-9'>
                <p>Name: {user.name}</p>
                <p>Email: {user.username}</p>
                <p>Department: {user.department}</p>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-danger" onClick={handleLogOut}>
              Log Out
            </Button>
          </Modal.Footer>
        </Modal>
      ) : <Login />}
    </div>
  );
};

export default SideBar;
