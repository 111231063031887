import { Button, Modal, Form, Spinner, Alert } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import axios from '../../../../constents/axios';
import styles from '../../../../styles/clone.module.css';

const CloneSubTopic = ({ show, onHide, courseName, topic, subTopic, onSuccess }) => {
  const [labels, setLabels] = useState([]);
  const [courses, setCourses] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedCourses, setSelectedCourses] = useState([]);
  const [selectedLabel, setSelectedLabel] = useState(null); // Updated to work with react-select
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const toggleSelectAll = () => {
    if (selectedCourses.length === courses.length) {
      setSelectedCourses([]);
    } else {
      setSelectedCourses(courses.map((course) => course.course_id));
    }
  };

  const toggleCourse = (courseId) => {
    setSelectedCourses((prev) =>
      prev.includes(courseId) ? prev.filter((id) => id !== courseId) : [...prev, courseId]
    );
  };

  const handleLabelChange = (selectedOption) => {
    setSelectedLabel(selectedOption);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const requestData = {
      course_id: courseName,
      topic,
      subTopic,
      cloneTo: selectedCourses,
    };

    try {
      await axios.post('/clone-subtopic', requestData);
      onHide();
      setShowSuccessMessage(true);
      setError(null);
      onSuccess();
      setSelectedLabel(null);
      setSelectedCourses([]);
    } catch (error) {
      console.error('Error cloning topic:', error);
      setError(error.response ? error.response.data.error : error.message);
    }
  };

  useEffect(() => {
    const fetchLabels = async () => {
      setLoading(true);
      try {
        const response = await axios.get('/fetch-label', {
          headers: {
              'autherisation': `--adminpanel--apikey--`,
          }
      });
        const options = response.data.data.map((label) => ({
          value: label.label,
          label: label.label,
        }));
        setLabels(options);
      } catch (error) {
        console.error('There was an error making the request:', error);
        setError(error.response ? error.response.data.error : error.message);
      } finally {
        setLoading(false);
      }
    };

    if (selectedLabel) {
      const fetchCourses = async () => {
        setLoading(true);
        try {
          const response = await axios.post('/fetch-courseId-label', { label: selectedLabel.value });
          setCourses(response.data.result);
        } catch (error) {
          console.error('There was an error making the request:', error);
          setError(error.response ? error.response.data.error : error.message);
        } finally {
          setLoading(false);
        }
      };
      fetchCourses();
    }

    fetchLabels();
  }, [selectedLabel]);

  return (
    <div>
      <Modal show={show} size="md" onHide={onHide}>
        <Modal.Header closeButton>
          <Modal.Title>Clone SubTopic</Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmit}>
          <Modal.Body>
            {loading && <Spinner animation="border" />}
            {error && <Alert variant="danger">{typeof error === 'string' ? error : JSON.stringify(error)}</Alert>}
            {showSuccessMessage && <Alert variant="success">Cloning successful!</Alert>}
            {!loading && !error && (
              <>
                <Select
                  value={selectedLabel}
                  onChange={handleLabelChange}
                  options={labels}
                  placeholder="Select Label"
                  isClearable
                  className={styles.select}
                />

                {courses.length > 0 && (
                  <div>
                    <Form.Check
                      type="checkbox"
                      label="Select All"
                      checked={selectedCourses.length === courses.length}
                      onChange={toggleSelectAll}
                      className={styles.selectAll}
                    />
                    {courses.map((course) => (
                      <div key={course.course_id} className={styles.checkBox}>
                        <Form.Check
                          type="checkbox"
                          label={course.title}
                          checked={selectedCourses.includes(course.course_id)}
                          onChange={() => toggleCourse(course.course_id)}
                        />
                      </div>
                    ))}
                  </div>
                )}
              </>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={onHide}>
              Close
            </Button>
            <Button variant="primary" type="submit">
              Clone Submit
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </div>
  );
};

export default CloneSubTopic;
