import React, { useEffect, useState } from 'react';
import axios from '../../constents/axios';
import SuccessModal from './SuccessModal';
import EditModal from './EditModal';

function LiveVideo({ subject, videoDetails, handleEditTrue, editIndex }) {
  const [show, setShow] = useState(false);
  const [videos, setVideos] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedValues, setSelectedValues] = useState(null);
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
  const [selectAllChecked, setSelectAllChecked] = useState(false);

  useEffect(() => {
    fetchVideos(subject);
  }, [subject]);

  const fetchVideos = async (courseName) => {
    try {
      const response = await axios.post('/ShowLiveVideo', { courseName });
      console.log("response",response.data);
      
      setVideos(response.data.student_data); // Assuming student_data contains the videos list
    } catch (error) {
      console.error('There was an error fetching the videos!', error);
    }
  };

  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;
    if (checked) {
      setSelectedCheckboxes([...selectedCheckboxes, value]);
    } else {
      setSelectedCheckboxes(selectedCheckboxes.filter((vid) => vid !== value));
    }
  };

  const handleSelectAllChange = (event) => {
    const { checked } = event.target;
    setSelectAllChecked(checked);
    if (checked) {
      const allVids = videos.map((video) => video.vid);
      setSelectedCheckboxes(allVids);
    } else {
      setSelectedCheckboxes([]);
    }
  };

  const deleteSelectedItems = () => {
    selectedCheckboxes.forEach((vid) => {
      DeleteLive(vid);
    });
    setSelectedCheckboxes([]);
    setSelectAllChecked(false);
  };

  const DeleteLive = async (id) => {
    const conf = window.confirm('Do you want to delete this video?');
    if (conf) {
      try {
        const response = await axios.post('http://localhost:3001/DeleteLiveVideo', {
          videoID: id,
        });
        if (response.status === 200) {
          setShow(true);
          setVideos((prevVideos) => prevVideos.filter((video) => video.id !== id));
        } else {
          console.error('Failed to delete video:', response);
        }
      } catch (error) {
        console.error('Error deleting video:', error);
      }
    }
  };

  const handlegetIndex = (index) => {
    const video_index = videos[index];
    setSelectedValues(video_index);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedValues(null);
  };

  const handleSave = (formData) => {
    const updatedVideos = videos.map((video) =>
      video.vid === selectedValues.vid ? { ...video, ...formData } : video
    );
    setVideos(updatedVideos);
  };

  return (
    <div>
      <h4>
        <b>{subject}</b>
      </h4>
      <table className="table table-bordered verticle-middle table-responsive-sm">
        <thead className="thead-dark">
          <tr>
            <th scope="col">Sl.No.</th>
            <th scope="col">Caption</th>
            <th scope="col">Topic</th>
            <th scope="col">Subtopic</th>
            <th scope="col">Classroom Link</th>
            <th scope="col">Faculty</th>
            <th scope="col">Action</th>
            <th scope="col">
              <input
                type="checkbox"
                name="Check_All"
                onChange={handleSelectAllChange}
                checked={selectAllChecked}
              />
              <button
                type="button"
                className="btn btn-sm btn-primary"
                data-toggle="modal"
                data-target="#exampleModalDelete"
                onClick={deleteSelectedItems}
              >
                <i className="fa fa-trash text-white"></i>
              </button>
            </th>
          </tr>
        </thead>

        <tbody>
          {videos.length > 0 ? (
            console.log("videos",videos),
            
            videos.map((video, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{video.caption}</td>
                <td>{video.topics}</td>
                <td>{video.subtopics}</td>
                <td>{video.classroom_link}</td>
                <td>{video.faculty_name}</td>
                <td>
                  <button
                    className="btn btn-sm btn-primary"
                    type="button"
                    onClick={() => DeleteLive(video.id)}
                  >
                    <i className="fa fa-trash text-white"></i>
                  </button>
                  <br />
                  <br />
                  <button
                    className="btn btn-sm btn-primary"
                    type="button"
                    onClick={() => handlegetIndex(index)}
                  >
                    <i className="fa fa-edit text-white"></i>
                  </button>
                </td>
                <td>
                  <center>
                    <input
                      type="checkbox"
                      id={`checkbox-${index}`}
                      className="name"
                      name={`checkbox-${index}`}
                      value={video.vid}
                      checked={selectedCheckboxes.includes(video.vid)}
                      onChange={handleCheckboxChange}
                    />
                  </center>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="8">No video details available.</td>
            </tr>
          )}
        </tbody>
      </table>
      {isModalOpen && (
        <EditModal
          selectedValues={selectedValues}
          closeModal={closeModal}
          handleSave={handleSave}
        />
      )}
      <SuccessModal show={show} handleClose={() => setShow(false)} message="Video Details Deleted successfully!" />
    </div>
  );
}

export default LiveVideo;
