// import axios from "axios";
import axios from '../../constents/axios';
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import SubjectforLivevideo from "./SubjectforLivevideo";
import "../../styles/addLiveVideo.module.css"
import SuccessModal from "./SuccessModal";
import LiveVideo from "./LiveVideo";

 const EditLiveVideo=({video_data})=> {
  const location = useLocation();

  const [edit,setEdit]=useState(true)
  const [show, setShow] = useState(false);
  const [isSubmitted,setIsSubmitted]=useState(false);
  // const { video_data } = location.state || {};

  const [formData, setFormData] = useState({
    subject: '',
    caption: '',
    topic: '',
    subtopic: '',
    classroom_link: '',
    faculty_name: '',
    description: ''
  });

  useEffect(() => {
    if (video_data) {
      console.log("video_data",video_data);
      
      setFormData({
        subject: video_data.subject || '',
        caption: video_data.caption || '',
        topic: video_data.topic || '',
        subtopic: video_data.subtopic || '',
        classroom_link: video_data.classroom_link || '',
        faculty_name: video_data.faculty_name || '',
        description: video_data.description || ''
      });
    }
  }, [video_data]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
     try {
      const response = await axios.post(`/UpdateLiveVideo`,
        {
          id:video_data.id,
          caption: formData.caption,
          topic: formData.topic,
          subtopic: formData.subtopic,
          classroom_link: formData.classroom_link,
          faculty_name: formData.faculty_name,
          description: formData.description
        }
      );
      console.log('Server response:', response);
      console.log('Video Updated successfully');
      // alert('Video Updated..!'); 
      setShow(true);
      setIsSubmitted(true);
    } catch (error) {
      console.error('There was an error updating the form!', error);
    }
  };

  const handleEditTrue=()=>{
    setEdit(true)
  }

  return (
    <div className="app pt-4">
      <div className="mainHeading">
        <h4>
          <b>UPDATE LIVE VIDEO</b>
        </h4>
      </div>
      <form onSubmit={handleSubmit} className="form">
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <div className="row pt-2">
                <div className="col-md-12">
                  <div className="form-group">
                    <label>Caption:</label>
                    <input
                      type="text"
                      className="form-control"
                      name="caption" value={formData.caption}
                      placeholder="Enter Caption"
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
              </div>

              <div className="row pt-2">
                <div className="col-md-12">
                  <div className="form-group">
                    <label>Topic:</label>
                    <input
                      type="text"
                      className="form-control"
                      name="topic"
                      value={formData.topic}
                      placeholder="Enter Topic"
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
              </div>

              <div className="row pt-2">
                <div className="col-md-12">
                  <div className="form-group">
                    <label>Subtopic:</label>
                    <input
                      type="text"
                      className="form-control"
                      value={formData.subtopic}
                      name="subtopic"
                      placeholder="Enter Subtopic"
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
              </div>

              <div className="row pt-2">
                <div className="col-md-12">
                  <div className="form-group">
                    <label>Classroom Link:</label>
                    <input
                      type="text"
                      className="form-control"
                      name="classroom_link"
                      value={formData.classroom_link}
                      placeholder="Enter Classroom Link"
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-6 form">
            <div className="form-group">
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <label>Faculty Name:</label>
                    <input
                      type="text"
                      className="form-control"
                      name="faculty_name"
                      value={formData.faculty_name}
                      placeholder="Enter Faculty Name"
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
              </div>

              <div className="row pt-2">
                <div className="col-md-12">
                  <div className="form-group">
                    <label>Description:</label>
                    <textarea
                      className="form-control"
                      name="description"
                      value={formData.description}
                      rows="5"
                      onChange={handleInputChange}
                    >
                      {formData.description}
                    </textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row pt-3">
            <div className="col-md-12">
              <div className="form-group">
                <button
                  className="btn btn-primary"
                  type="submit" onClick={handleEditTrue={handleEditTrue}}
                >
                  Update
                </button>
              </div>
            </div>
          </div>
      <SuccessModal show={show} handleClose={() => setShow(false)} message="Video Details Updated succesfully..!"/>

        </div>
      </form>
      {video_data.subject  &&  edit &&(
        <div className="video-details pt-5">
          <LiveVideo subject={video_data.subject}/>
        </div>
      )}
    </div>
  );
}

export default EditLiveVideo;
