import React, { useEffect, useState } from 'react';
import styles from '../../../../styles/materialVideo.module.css';
import { Form, Button, Modal, ListGroup } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import axios from '../../../../constents/axios';
import { GrChapterAdd } from "react-icons/gr";
import { CiSquareRemove } from "react-icons/ci";

const AddNewVideo = () => {
  const [materialData, setMaterialData] = useState({});
  const [caption, setCaption] = useState('');
  const [link, setLink] = useState('');
  const [date, setDate] = useState('');
  const [youtubeLink, setYoutubeLink] = useState('');
  const [publishDemo, setPublishDemo] = useState('0');
  const [chapterData, setChapterData] = useState([]); 
  const [currentChapterName, setCurrentChapterName] = useState(''); 
  const [currentChapterTime, setCurrentChapterTime] = useState(''); 
  const [showChapterForm, setShowChapterForm] = useState(false);
  const [showQuestionForm, setShowQuestionForm] = useState(false); 
  const [submitted, setSubmitted] = useState(false); 
  const [questionData, setQuestionData] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState('');
  const [currentOptions, setCurrentOptions] = useState(['', '', '', '']);
  const [currentAnswerOption, setCurrentAnswerOption] = useState('');
  const [currentQuestionTime, setCurrentQuestionTime] = useState("");
  const [currentQuestionTimeInMinutes, setCurrentQuestionTimeInMinutes] = useState(0);  
  const [currentExplanation, setCurrentExplanation] = useState('');
  const [videoId, setVideoId] = useState(null); 
  const navigate = useNavigate();

  const { courseName, currentTopicName, currentSubTopicName } = materialData;

  const handleAddNewVideo = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`/add-newvideo`, {
        course_id: `${courseName}`,
        topics: `${currentTopicName}`,
        subtopic: `${currentSubTopicName}`,
        caption: `${caption}`,
        materialLink: `${link}`,
        youtubeLink: `${youtubeLink}`,
        is_free: publishDemo,
        date: `${date}`,
        chapters: chapterData 
      });
      const id = response.data.data;
      setVideoId(id); 
      setShowQuestionForm(true);
      setSubmitted(true);
    } catch (error) {
      console.error('Error adding video:', error);
    }
  };

  const handleAddVideoQuestion = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`/add-videoQuestion`, {
        course_id: `${courseName}`,
        video_id: videoId,
        questionData
      });
      navigate(`/studentJourney/${courseName}`);
    } catch (error) {
      console.error('Error adding video:', error);
    }
  };

  const handleCloseQuestionForm = () => {
    setShowQuestionForm(false);
    if (submitted) {
      navigate(`/studentJourney/${courseName}`);
    }
  };

  const handleChangeCaption = (e) => setCaption(e.target.value);
  const handleChangeLink = (e) => setLink(e.target.value);
  const handleChangeDate = (e) => setDate(e.target.value);
  const handleChangeYoutubeLink = (e) => setYoutubeLink(e.target.value);
  const handleChangePublishDemo = (e) => setPublishDemo(e.target.value === 'publishYes' ? 1 : 0);
  const handleChapterNameChange = (e) => setCurrentChapterName(e.target.value);
  const handleChapterTimeChange = (e) => setCurrentChapterTime(e.target.value);

  const addChapter = () => {
    if (currentChapterName && currentChapterTime) {
      setChapterData([...chapterData, {
        title: currentChapterName,
        start: currentChapterTime
      }]); 
      setCurrentChapterName(''); 
      setCurrentChapterTime(''); 
    }
  };

  const removeChapter = (index) => {
    setChapterData(chapterData.filter((_, i) => i !== index)); 
  };


  const handleQuestionChange = (e) => setCurrentQuestion(e.target.value);
  const handleOptionChange = (e, index) => {
    const options = [...currentOptions];
    options[index] = e.target.value;
    setCurrentOptions(options);
  };
  const handleAnswerOptionChange = (e) => setCurrentAnswerOption(e.target.value);
  const handleQuestionTimeChange = (e) => {
    const value = e.target.value;
    setCurrentQuestionTime(value);
    const [hours, minutes] = value.split(':').map(Number);
    const totalMinutes = (hours || 0) * 60 + (minutes || 0);
    setCurrentQuestionTimeInMinutes(totalMinutes);
  };
  
  const handleExplanationChange = (e) => setCurrentExplanation(e.target.value);

  const addQuestion = () => {
    if (currentQuestion && currentAnswerOption && currentExplanation) {
      setQuestionData([...questionData, {
        question: currentQuestion,
        options: currentOptions,
        answer: currentAnswerOption,
        questionTime: currentQuestionTime,
        explanation: currentExplanation
      }]);
      setCurrentQuestion('');
      setCurrentOptions(['', '', '', '']);
      setCurrentAnswerOption('');
      setCurrentQuestionTime('');
      setCurrentExplanation('');
    }
  };

  const removeQuestion = (index) => {
    setQuestionData(questionData.filter((_, i) => i !== index));
  };

  useEffect(() => {
    const storedMaterialData = JSON.parse(localStorage.getItem('materialData'));
    if (storedMaterialData) {
      setMaterialData(storedMaterialData);
    }
  }, []);

  return (
    <div>
      <div className={styles.mainHeadDiv}>
        <h3 className={styles.mainHead}>ADD ONLINE/LIVLINE VIDEO</h3>
        <div>
          <div className='row'>
            <div className='col-md-3'>
              <div className={styles.header}><strong>Course Name:</strong></div>
              <div className={styles.header}><strong>Topic Name:</strong></div>
              <div className={styles.header}><strong>SubTopic Name:</strong></div>
            </div>
            <div className='col-md-4'>
              <div className={styles.header}><span>{courseName}</span></div>
              <div className={styles.header}><span>{currentTopicName}</span></div>
              <div className={styles.header}><span>{currentSubTopicName}</span></div>
            </div>
          </div>
          <form onSubmit={handleAddNewVideo}>
            <div className='row'>
              <div className='col-md-6'>
                <div className={styles.inputDiv}>
                  <label htmlFor="caption"><strong>Material Caption</strong></label>
                  <Form.Control type="text" onChange={handleChangeCaption} required id="caption" placeholder="Give a Caption" />
                </div>
                <div className={styles.inputDiv}>
                  <label htmlFor="sourceLink"><strong>Source Link</strong></label>
                  <Form.Control type="text" onChange={handleChangeLink} required id="sourceLink" placeholder="Enter Link" />
                </div>
                <div className={styles.addBtns}>
                  <Button variant="outline-secondary" onClick={() => setShowChapterForm(true)} className="mb-2">
                    Click to add Chapters
                  </Button>
                </div>
                <Modal show={showChapterForm} onHide={() => setShowChapterForm(false)}>
                  <Modal.Header closeButton>
                    <Modal.Title>Set Chapter Times</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div className="inputDiv">
                      <Form>
                        <label htmlFor="chapterName"><strong>Chapter Name</strong></label>
                        <Form.Control
                          type="text"
                          id="chapterName"
                          value={currentChapterName}
                          required
                          onChange={handleChapterNameChange}
                        />
                        <label htmlFor="chapterTime"><strong>Chapter Time</strong></label>
                        <Form.Control
                          type="time"
                          step="1"
                          id="chapterTime"
                          value={currentChapterTime}
                          required
                          onChange={handleChapterTimeChange} 
                        />
                        <div className={styles.addChapter}>
                          <Button variant="outline-warning" onClick={addChapter} className="mt-2">
                            <GrChapterAdd />  Add Chapter
                          </Button>
                        </div>
                      </Form>
                    </div>
                    {chapterData.length > 0 && (
                      <div className="mt-3">
                        <strong>Chapters Added:</strong>
                        <ListGroup>
                          {chapterData.map((chapter, index) => (
                            <ListGroup.Item key={index} className="d-flex justify-content-between align-items-center">
                              <div>{chapter.title} - {chapter.start}</div>

                              <Button
                                variant="outline-danger"
                                size="sm"
                                onClick={() => removeChapter(index)}
                              >
                                <CiSquareRemove />
                              </Button>
                            </ListGroup.Item>
                          ))}
                        </ListGroup>
                      </div>
                    )}
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="primary" onClick={() => setShowChapterForm(false)}>
                      Save Changes
                    </Button>
                  </Modal.Footer>
                </Modal>
                <br />
                {/* <div className={styles.inputDiv}>
                  <label htmlFor="updateDetails"><strong>Do you want to update more details?</strong></label>
                  <div className={styles.checkDiv}>
                    <Form.Check type="radio" name="updateDetails" label={`Yes`} id={`updateYes`} required />
                    <Form.Check type="radio" name="updateDetails" label={`No`} id={`updateNo`} required />
                  </div>
                </div> */}
              </div>
              <div className='col-md-6'>
                <div className={styles.inputDiv}>
                  <label htmlFor="youtubeLink"><strong>Youtube Link</strong></label>
                  <Form.Control type="text" onChange={handleChangeYoutubeLink} id="youtubeLink" placeholder="Enter Youtube Link" />
                </div>
                <div className={styles.inputDiv}>
                  <label htmlFor="date"><strong>Date</strong></label>
                  <Form.Control type="date" id="date" onChange={handleChangeDate} required aria-label="Date" />
                  <Form.Control type="time" aria-label="Time" />
                </div>
                <div className={styles.inputDiv}>
                  <label htmlFor="publishDemo"><strong>Do you want to publish this as demo?</strong></label>
                  <div className={styles.checkDiv}>
                    <Form.Check type="radio" name="publishDemo" label={`Yes`} value={`publishYes`} onChange={handleChangePublishDemo} required />
                    <Form.Check type="radio" name="publishDemo" label={`No`} value={`publishNo`} onChange={handleChangePublishDemo} required />
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.addBtns}>
              <Button type="submit" variant="outline-success" className="mb-2">
                Submit
              </Button>
            </div>
          </form>
        </div>
        <Modal show={showQuestionForm} onHide={handleCloseQuestionForm}>
          <Form onSubmit={handleAddVideoQuestion}>

            <Modal.Header closeButton>
              <Modal.Title>Set Questions</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form >
                <label><strong>Question</strong></label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  value={currentQuestion}
                  placeholder="Enter question"
                  onChange={handleQuestionChange}
                  required
                />
                <label><strong>Options</strong></label>
                {currentOptions.map((option, index) => (
                  <Form.Control
                    key={index}
                    type="text"
                    style={{ marginBottom: "10px" }}
                    placeholder={`Option ${index + 1}`}
                    value={option}
                    onChange={(e) => handleOptionChange(e, index)}
                    required
                  />
                ))}
                <div className="row">
                  <div className='col-md-6'>
                    <label><strong>Answer Option</strong></label>
                    <Form.Control type="number" value={currentAnswerOption} placeholder="Enter option number"
                      onChange={(e) => {
                        const value = e.target.value;
                        if (value === "" || /^[1-4]$/.test(value)) {
                          handleAnswerOptionChange(e);
                        }
                      }}
                      onKeyDown={(e) => {
                        if (!["1", "2", "3", "4"].includes(e.key) && e.key !== "Backspace" && e.key !== "Delete") {
                          e.preventDefault();
                        }
                      }}
                      required
                    />
                  </div>
                  <div className='col-md-6'>
                    <label><strong>Question Time</strong></label>
                    <Form.Control
                      type="time"
                      step="1"
                      value={currentQuestionTime}
                      placeholder="Enter time"
                      onChange={(e) => handleQuestionTimeChange(e)}
                      required
                    />
                  </div>
                </div>
                <label><strong>Explanation</strong></label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  value={currentExplanation}
                  placeholder='Enter explanation here'
                  onChange={handleExplanationChange}
                  required
                />
                <div className={styles.addChapter}>
                  <Button variant="outline-warning" onClick={addQuestion} className="mt-2">
                    <GrChapterAdd /> Add Question
                  </Button>
                </div>
              </Form>

              {questionData.length > 0 && (
                <div className="mt-3">
                  <strong>Added Questions:</strong>
                  <ListGroup>
                    {questionData.map((question, index) => (
                      <ListGroup.Item key={index} className="justify-content-between align-items-center">
                        <div>Question: {question.question}</div>
                        <div>Answer: {question.answer}</div>
                        <div>Question Time: {question.questionTime}</div>
                        <div>Explanation: {question.explanation}</div>
                        <div>Options: <ol>
                          {question.options.map((option, index) => <li>{option}</li>)}</ol>
                        </div>
                        <div className={styles.addChapter}>
                          <Button
                            variant="outline-danger"
                            size="sm"
                            onClick={() => removeQuestion(index)}
                          >
                            <CiSquareRemove className='fs-5' />
                          </Button>
                        </div>
                      </ListGroup.Item>
                    ))}
                  </ListGroup>
                </div>
              )}
            </Modal.Body>
            <Modal.Footer>
              <Button variant="light" onClick={handleCloseQuestionForm}>
                Cancel
              </Button>
              <Button variant="outline-primary" type='submit'>
                Save Changes
              </Button>
            </Modal.Footer>

          </Form>
        </Modal>
      </div>
    </div>
  );
};

export default AddNewVideo;
