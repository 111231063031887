import React, { useEffect, useState } from 'react'
// import axios from "axios"
import axios from '../../constents/axios'
import LiveVideo from './LiveVideo';
import "../../styles/addLiveVideo.module.css"
import EditLiveVideo from './EditLiveVideo';
function SubjectforLivevideo() {
    const [subject, setSubject] = useState([]);
    const [selectedSubject, setSelectedSubject] = useState('');
    const [videoDetails, setVideoDetails] = useState('');
    const [editIndex,setEditIndex]=useState(0)
    const [edit,setEdit]=useState(false)
    useEffect(() => {
        // Fetch subjects from server when component mounts
        axios.get('/subjects')
          .then(response => {
            setSubject(response.data);
          })
          .catch(error => {
            console.error('Error fetching subjects:', error);
          });
      }, []);
      
   useEffect(() => {
     if (selectedSubject) {
       axios.post("/ShowLiveVideo", {subject: selectedSubject})
         .then((response) => {
           setVideoDetails(response.data.student_data); 
          //  console.log(setVideoDetails);
         })
         .catch((error) => {
           console.error("Error fetching video details:", error);
           // Handle error state or display error message to the user
         });
     }
   }, [selectedSubject]);
   const handleChange = (e) => {
    const {  value } = e.target;
    setSelectedSubject(value);
  };
  const handleEditTrue=()=>{
    setEdit(true)
  }
  return (
    <div className="app pb-2" style={{ alignContent: "flex-start" }}>
      <div className="mainHeading">
        <h4>
          <b> LIVE VIDEO</b>
        </h4>
      </div>
      <form action="" className="form">
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <label>Subject:</label>
              <select
                className="form-control"
                onChange={handleChange}
                name="subject"
                required
              >
                <option value="">Select Subject</option>
                {subject.map((subjects, index) => (
                  <option key={index} value={subjects}>
                    {subjects}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
        {selectedSubject && videoDetails && !edit&&(
          <div className="video-details pt-5">
            <LiveVideo subject={selectedSubject} videoDetails={videoDetails} handleEditTrue={()=>handleEditTrue()} editIndex={(value)=>setEditIndex(value)}/>
          </div>
        )}
      </form>
      {selectedSubject && videoDetails && edit&&(
        <EditLiveVideo video_data={videoDetails[editIndex]} />
      )}
    </div>
  );
}

export default SubjectforLivevideo