import React from 'react';
import styles from '../../styles/home.module.css'; // Importing the CSS file

const Home = () => {
  return (
    <div className={styles.homeContainer}>
      <div className={styles.homeContent}>Admin Panel</div>
    </div>
  );
};

export default Home;
