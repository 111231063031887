import React, { useState } from 'react';
import { Button, Modal, Form, Spinner } from 'react-bootstrap';
import AWS from 'aws-sdk';
import axios from '../../constents/axios'; // Use your configured Axios instance

AWS.config.update({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID, // Your access key
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY, // Your secret key
});
const s3 = new AWS.S3();

const UploadReel = ({ show, handleClose }) => {
  const [error, setError] = useState('');
  const [file, setFile] = useState(null);
  const [isUploading, setIsUploading] = useState(false); // State for tracking upload status
  const [successMessage, setSuccessMessage] = useState('');

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    
    if (selectedFile && !selectedFile.type.startsWith('video/')) {
      setError('Please upload a valid video file.');
      setFile(null);
      setSuccessMessage(''); // Reset success message
    } else {
      setError('');
      setFile(selectedFile);
      setSuccessMessage(''); // Reset success message
    }
  };

  const handleUpload = () => {
    if (file) {
      const params = {
        Bucket: process.env.REACT_APP_AWS_S3_BUCKET,
        Key: `${Date.now()}-${file.name}`,
        Body: file,
        ContentType: file.type,
        ACL: 'public-read',
      };

      setIsUploading(true); // Set uploading state to true

      s3.upload(params)
        .promise()
        .then(data => {
          setSuccessMessage('Upload successful!'); // Set success message
          setIsUploading(false); // Reset uploading state
          
          // Send the uploaded video URL to the backend
          return axios.post('/createReel', { reel: data.Location });
        })
        .then(() => {
          setFile(null); // Reset file input
        })
        .catch(err => {
          console.error('Error uploading reel:', err);
          setError('Failed to upload reel. Please try again.');
          setIsUploading(false); // Reset uploading state on error
        });
    }
  };

  const handleModalClose = () => {
    handleClose();
    setIsUploading(false); // Reset uploading state when modal is closed
    setSuccessMessage(''); // Reset success message
    setFile(null); // Reset file input
  };

  return (
    <Modal show={show} onHide={handleModalClose}>
      <Modal.Header closeButton>
        <Modal.Title>Upload New Reel</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isUploading ? ( // Show spinner while uploading
          <div className="d-flex justify-content-center align-items-center">
            <Spinner animation="border" role="status" />
            <span className="ms-2">Uploading...</span>
          </div>
        ) : successMessage ? (
          <div className="text-success mt-2">{successMessage}</div>
        ) : (
          <>
            <Form>
              <Form.Group controlId="formFileSm">
                <Form.Label>Choose a video file</Form.Label>
                <Form.Control 
                  size="sm" 
                  type="file" 
                  accept="video/*" 
                  onChange={handleFileChange} 
                />
                {error && <div className="text-danger mt-2">{error}</div>}
              </Form.Group>
            </Form>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleModalClose}>
          Close
        </Button>
        {!isUploading && !successMessage && (
          <Button 
            variant="primary" 
            onClick={handleUpload}
          >
            Save Changes
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
}

export default UploadReel;
