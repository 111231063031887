import { Button, Modal, Form, Spinner, Alert } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import axios from '../../../../constents/axios';
import styles from '../../../../styles/clone.module.css';
import Select from 'react-select';

const CloneMaterial = ({ show, onHide, courseName, topic, subTopic, id, type, onSuccess }) => {
  const [labels, setLabels] = useState([]);
  const [courses, setCourses] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedCourses, setSelectedCourses] = useState([]);
  const [selectedLabel, setSelectedLabel] = useState(null); // Update to work with Select
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  useEffect(() => {
    const fetchLabels = async () => {
      setLoading(true);
      try {
        const response = await axios.get('/fetch-label', {
          headers: {
              'autherisation': `--adminpanel--apikey--`,
          }
      });
        setLabels(response.data.data.map(label => ({ value: label.label, label: label.label }))); // Format for react-select
      } catch (error) {
        console.error("There was an error making the request:", error);
        setError(error.response ? error.response.data.error : error.message);
      } finally {
        setLoading(false);
      }
    };
    fetchLabels();
  }, []);

  useEffect(() => {
    if (selectedLabel) {
      const fetchCourses = async () => {
        setLoading(true);
        try {
          const response = await axios.post('/fetch-courseId-label', { label: selectedLabel.value });
          setCourses(response.data.result);
        } catch (error) {
          console.error("There was an error making the request:", error);
          setError(error.response ? error.response.data.error : error.message);
        } finally {
          setLoading(false);
        }
      };
      fetchCourses();
    }
  }, [selectedLabel]);

  const toggleSelectAll = () => {
    if (selectedCourses.length === courses.length) {
      setSelectedCourses([]);
    } else {
      setSelectedCourses(courses.map(course => course.course_id));
    }
  };

  const toggleCourse = (courseId) => {
    setSelectedCourses((prev) =>
      prev.includes(courseId) ? prev.filter((id) => id !== courseId) : [...prev, courseId]
    );
  };

  const handleLabelChange = (selectedOption) => {
    setSelectedLabel(selectedOption);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const requestData = {
      course_id: courseName,
      topic,
      subTopic,
      id,
      type,
      cloneTo: selectedCourses,
    };
    
    try {
      const response = await axios.post('/clone-material', requestData);
      onHide();
      setError(null);
      onSuccess();
      setShowSuccessMessage(true);
      // Optionally, reset the state after success
      setSelectedLabel(null);
      setSelectedCourses([]);
    } catch (error) {
      console.error('Error cloning topic:', error);
      setError(error.response ? error.response.data.error : error.message);
    }
  };

  return (
    <div>
      <Modal show={show} size="md" onHide={onHide}>
        <Modal.Header closeButton>
          <Modal.Title>Clone Material</Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmit}>
          <Modal.Body>
            {loading && <Spinner animation="border" />}
            {error && <Alert variant="danger">{typeof error === 'string' ? error : JSON.stringify(error)}</Alert>}
            {showSuccessMessage && <Alert variant="success">Cloning successful!</Alert>}
            {!loading && !error && (
              <>
                <Select
                  value={selectedLabel}
                  onChange={handleLabelChange}
                  options={labels}
                  placeholder="Select Label"
                  isClearable
                />
                {courses?.length > 0 && (
                  <div>
                    <Form.Check
                      type="checkbox"
                      label="Select All"
                      checked={selectedCourses.length === courses.length}
                      onChange={toggleSelectAll}
                      className={styles.selectAll}
                    />
                    {courses?.map((course) => (
                      <div key={course.course_id} className={styles.checkBox}>
                        <Form.Check
                          type="checkbox"
                          label={course.title}
                          checked={selectedCourses.includes(course.course_id)}
                          onChange={() => toggleCourse(course.course_id)}
                        />
                      </div>
                    ))}
                  </div>
                )}
              </>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={onHide}>Close</Button>
            <Button variant="primary" type="submit">Clone Submit</Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </div>
  );
};

export default CloneMaterial;
