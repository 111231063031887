import React, { useEffect, useState } from 'react';
import styles from '../../../../styles/materialPDF.module.css';
import { Form, Button } from 'react-bootstrap';
import axios from '../../../../constents/axios';
import { useNavigate } from 'react-router-dom';

const AddNewPDF = () => {
  const [materialData, setMaterialData] = useState({});
  const [caption, setCaption] = useState('');
  const [link, setLink] = useState('');
  const [date, setDate] = useState('');
  const [publishDemo, setPublishDemo] = useState(null);
  const navigate = useNavigate();

  const { courseName, currentTopicName, currentSubTopicName } = materialData;

  const handleAddNewPDF = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`/add-newpdf`, {
        sl_no: "0",
        course_id: `${courseName}`,
        topics: `${currentTopicName}`,
        subtopic: `${currentSubTopicName}`,
        caption: `${caption}`,
        materialLink: `${link}`,
        is_free: publishDemo,
        date: `${date}`
      });

      navigate(`/studentJourney/${courseName}`);
    } catch (error) {
      console.error('Error adding topic:', error);
    }
  };

  const handleChangeCaption = (e) => {
    setCaption(e.target.value);
  };

  const handleChangeLink = (e) => {
    setLink(e.target.value);
  };

  const handleChangeDate = (e) => {
    setDate(e.target.value);
  };

  const handleChangePublishDemo = (e) => {
    setPublishDemo(e.target.value === "1" ? 1 : 0);
  };

  useEffect(() => {
    const storedMaterialData = JSON.parse(localStorage.getItem('materialData'));
    if (storedMaterialData) {
      setMaterialData(storedMaterialData);
    }
  }, []);

  return (
    <div className={styles.addPdf}>
      <div className={styles.mainHeadDiv}>
        <div className='row'>
          <div className='col-md-3'>
            <div className={styles.header}>
              <strong>Course Name:</strong> 
            </div>
            <div className={styles.header}>
              <strong>Topic Name:</strong> 
            </div>
            <div className={styles.header}>
              <strong>SubTopic Name:</strong> 
            </div>
          </div>
          <div className='col-md-4'>
            <div className={styles.header}>
              <span>{courseName}</span>
            </div>
            <div className={styles.header}>
              <span>{currentTopicName}</span>
            </div>
            <div className={styles.header}>
              <span>{currentSubTopicName}</span>
            </div>
          </div>
        </div>
        <h3 className={styles.mainHead}>CREATE PDF MATERIAL</h3>
        <div>
          <form onSubmit={handleAddNewPDF}>
            <div className='row'>
              <div className='col-md-6'>
                <div className={styles.inputDiv}>
                  <label htmlFor=""><strong>Material Caption</strong></label>
                  <Form.Control type="text" placeholder="Give a Caption" required onChange={handleChangeCaption} className={styles.input} />
                </div>
                <div className={styles.inputDiv}>
                  <label htmlFor=""><strong>Material Link</strong></label>
                  <Form.Control type="text" placeholder="Enter Material Link" required onChange={handleChangeLink} className={styles.input} />
                </div>
              </div>
              <div className='col-md-6'>
                <div className={styles.inputDiv}>
                  <label htmlFor=""><strong>Date</strong></label>
                  <Form.Control aria-label="Date" type="date" required onChange={handleChangeDate} className={styles.input} />
                </div>
                <div className={styles.inputDiv}>
                  <label htmlFor="publishDemo"><strong>Do you want to publish as demo?</strong></label>
                  <div className={styles.checkDiv}>
                    <Form.Check
                      type="radio"
                      name="publishDemo"
                      label={`Yes`}
                      id={`publishYes`}
                      value="1"
                      onChange={handleChangePublishDemo}
                      required
                    />
                    <Form.Check
                      type="radio"
                      name="publishDemo"
                      label={`No`}
                      id={`publishNo`}
                      value="0"
                      onChange={handleChangePublishDemo}
                      required
                    />
                  </div>
                </div>
              </div>
              <div className={styles.saveBtnDiv}>
                <Button className={styles.saveBtn} type='submit'>Save</Button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default AddNewPDF;