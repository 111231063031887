import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import axios from "../../../../constents/axios";

const DeleteTopics = ({ show, onHide, topicName, courseName, onSuccess }) => {
    const handleDeleteTopic = async () => {
        try {
            await axios.delete(`/delete-topic`, {
                data: {
                    course_id: courseName,
                    topics: topicName,
                }
            });
            onSuccess(); 
            onHide(); 
        } catch (error) {
            console.error('Error deleting topic:', error);
        }
    };

    return (
        <Modal show={show} size="lg" backdrop="static" onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">Delete Topic</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <span>Deleting this Topic could wipe out everything else attached to it, like a digital domino effect.</span>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>Cancel</Button>
                <Button variant="danger" onClick={handleDeleteTopic}>Delete Topic</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default DeleteTopics;
