import React, { useState, useEffect } from 'react';
import { Button, Modal, Form, Alert } from 'react-bootstrap';
import axios from '../../../../constents/axios';

const AddMaterialToMiddle = ({ show, onHide, courseName, currentTopicName, currentSubTopicName, currentPriority, onSuccess }) => {
  const [materialType, setMaterialType] = useState('');
  const [materialId, setMaterialId] = useState('');
  const [error, setError] = useState(null);

  const handleAddMaterial = async (e) => {
    e.preventDefault();
    if (!materialId || !materialType) {
      setError('Both Material ID and Material Type are required.');
      return;
    }
    try {
      const response = await axios.post(`/add-to-middle-material`, {
        id: 0,
        course_id: courseName,
        topics: currentTopicName,
        subtopic: currentSubTopicName,
        material_type: materialType,
        material_id: materialId,
        priority: parseInt(currentPriority) + 1 
      });

      onSuccess(); 
      setMaterialId('');
      setMaterialType('');
      setError(null);
      onHide(); 
    } catch (error) {
      setError( (error.response?.data?.error || error.message));
      console.error('Error adding material:', error);
    }
  };

  const handleChangeId = (e) => {
    setMaterialId(e.target.value);
  };

  const handleChangeType = (e) => {
    setMaterialType(e.target.value);
  };

  useEffect(() => {
    if (error) {
      const timer = setTimeout(() => setError(null), 3000); 
      return () => clearTimeout(timer); 
    }
  }, [error]);

  return (
    <Modal show={show} size="md" backdrop="static" onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Add Material</Modal.Title>
      </Modal.Header>
      <Form>
        <Modal.Body>
          {error && <Alert variant="danger">{error}</Alert>}
          <Form.Control size="sm" required onChange={handleChangeId} type="number" placeholder="Material ID" value={materialId} />
          <br />
          <Form.Select size="sm" required onChange={handleChangeType} value={materialType}>
            <option value="">Select Material Type</option>
            <option value="pdf">PDF</option>
            <option value="video">Video</option>
          </Form.Select>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleAddMaterial}>Add Existing Material</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default AddMaterialToMiddle;
