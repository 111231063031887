import axios from '../../../constents/axios';
import React, { useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import AWS from 'aws-sdk';

const AddModal = ({ show, onHide, onSuccess }) => {
    const [caption, setCaption] = useState('');
    const [banner, setBanner] = useState(null);
    const [file, setFile] = useState(null);
    const [courseId, setCourseId] = useState('');
    const [imageError, setImageError] = useState('');
    const [pdfError, setPdfError] = useState('');

    AWS.config.update({
        accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
        secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
        region: process.env.REACT_APP_AWS_REGION,
    });

    const s3 = new AWS.S3();

    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const day = String(currentDate.getDate()).padStart(2, '0');

    const uploadToS3 = (file, folder) => {
        return new Promise((resolve, reject) => {
            const params = {
                Bucket: process.env.REACT_APP_S3_BUCKET_NAME,
                Key: `${folder}/${year}/${month}/${day}/${file.name}`,
                Body: file,
                ContentType: file.type,
                ACL: 'public-read',
            };

            s3.upload(params, (err, data) => {
                if (err) {
                    reject(err);
                } else {
                    resolve({
                        folder: folder,
                        fileName: file.name,
                        location: data.Location,
                    });
                }
            });
        });
    };

    const handleSave = async (e) => {
        e.preventDefault(); 

        try {
            let bannerUrl = null;
            let fileUrl = null;
            let bannerFolder = null;
            let fileFolder = null;
            let bannerName = null;
            let fileName = null;

            if (banner) {
                const bannerUpload = await uploadToS3(banner, 'QBook');
                bannerUrl = bannerUpload.location;
                bannerFolder = bannerUpload.folder;
                bannerName = bannerUpload.fileName;
            }
            if (file) {
                const fileUpload = await uploadToS3(file, 'QBook');
                fileUrl = fileUpload.location;
                fileFolder = fileUpload.folder;
                fileName = fileUpload.fileName;
            }

            await axios.post('/addEbook', {
                caption,
                banner: `${bannerFolder}/${year}/${month}/${day}/${bannerName}`,
                file: `${fileFolder}/${year}/${month}/${day}/${fileName}`,
                courseId,
            }, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            onSuccess();
            setCaption('');
            setBanner(null);
            setFile(null);
            setCourseId('');
        } catch (error) {
            console.error('Error adding eBook:', error);
        }

        onHide();
    };

    return (
        <div>
            <Modal show={show} backdrop="static" onHide={onHide}>
                <Modal.Header closeButton>
                    <Modal.Title>Add eBook</Modal.Title>
                </Modal.Header>
                <Form onSubmit={handleSave}>
                    <Modal.Body>
                        <Form.Group controlId="formCaption">
                            <Form.Label>Caption</Form.Label>
                            <Form.Control
                                type="text"
                                value={caption}
                                onChange={(e) => setCaption(e.target.value)}
                                required
                            />
                        </Form.Group>
                        <Form.Group controlId="formBanner" className="mt-3">
                            <Form.Label>Banner</Form.Label>
                            <Form.Control
                                type="file"
                                accept="image/*"
                                required
                                isInvalid={imageError}
                                onChange={(e) => {
                                    const file = e.target.files[0];
                                    if (file && file.type.startsWith('image/')) {
                                        setBanner(file);
                                        setImageError('');
                                    } else {
                                        setImageError('Please select a valid image file.');
                                        e.target.value = null;
                                    }
                                }}
                            />
                            <Form.Control.Feedback type="invalid">{imageError}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group controlId="formFile" className="mt-3">
                            <Form.Label>File</Form.Label>
                            <Form.Control
                                type="file"
                                accept=".pdf"
                                isInvalid={!!pdfError}
                                onChange={(e) => {
                                    const file = e.target.files[0];
                                    if (file && file.type === 'application/pdf') {
                                        setFile(file);
                                        setPdfError('');
                                    } else {
                                        setPdfError('Please select a valid PDF file.');
                                        e.target.value = null;
                                    }
                                }}
                            />
                            <Form.Control.Feedback type="invalid">{pdfError}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group controlId="formCourse" className="mt-3">
                            <Form.Label>Ebook Course</Form.Label>
                            <Form.Select
                                value={courseId}
                                onChange={(e) => setCourseId(e.target.value)}
                                aria-label="Select your Ebook Course"
                                required
                            >
                                <option value="">Select your Ebook Course</option>
                                <option value="CUET UG">CUET UG</option>
                                <option value="CUET PG">CUET PG</option>
                                <option value="UGC NET">UGC NET</option>
                                <option value="CSIR">CSIR NET</option>
                            </Form.Select>
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={onHide}>
                            Cancel
                        </Button>
                        <Button variant="primary" type="submit">
                            Submit
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </div>
    );
};

export default AddModal;
