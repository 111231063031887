import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import SuccessModal from './SuccessModal';

const EditModal = ({ selectedValues, closeModal, handleSave }) => {

  const [formData, setFormData] = useState({
    caption: '',
    topic: '',
    subtopic: '',
    classroom_link: '',
    faculty_name: '',
    description: ''
  });

  useEffect(() => {
    if (selectedValues) {
      setFormData({
        caption: selectedValues.caption || '',
        topic: selectedValues.topics || '',
        subtopic: selectedValues.subtopics || '',
        classroom_link: selectedValues.classroom_link || '',
        faculty_name: selectedValues.faculty_name || '',
        description: selectedValues.description || '',
      });
    }
  }, [selectedValues]);


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  
  const handleSubmit = async (e) => {
    e.preventDefault();
     try {
      const response = await axios.post(`http://localhost:3001/UpdateLiveVideo`,
        {
          id:selectedValues.id,
          caption: formData.caption,
          topic: formData.topic,
          subtopic: formData.subtopic,
          classroom_link: formData.classroom_link,
          faculty_name: formData.faculty_name,
          description: formData.description
        }
      );
      console.log('Server response:', response);
      console.log('Video Updated successfully');
      closeModal();
    handleSave(formData);
    } catch (error) {
      console.error('There was an error updating the form!', error);
    }
  };

  return (
    <Modal show={!!selectedValues} >
      <Modal.Header closeButton onHide={closeModal}>
        <Modal.Title>Edit Live Video</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form >
          <Form.Group controlId="formCaption">
            <Form.Label>Caption</Form.Label>
            <Form.Control
              type="text"
              name="caption"
              value={formData.caption}
              onChange={handleChange}
              required
            />
          </Form.Group>
          <Form.Group controlId="formTopic">
            <Form.Label>Topic</Form.Label>
            <Form.Control
              type="text"
              name="topic"
              value={formData.topic}
              onChange={handleChange}
              required
            />
          </Form.Group>
          <Form.Group controlId="formSubtopic">
            <Form.Label>Subtopic</Form.Label>
            <Form.Control
              type="text"
              name="subtopic"
              value={formData.subtopic}
              onChange={handleChange}
              required
            />
          </Form.Group>
          <Form.Group controlId="formClassroomLink">
            <Form.Label>Classroom Link</Form.Label>
            <Form.Control
              type="text"
              name="classroom_link"
              value={formData.classroom_link}
              onChange={handleChange}
              required
            />
          </Form.Group>
          <Form.Group controlId="formFaculty">
            <Form.Label>Faculty Name</Form.Label>
            <Form.Control
              type="text"
              name="faculty_name"
              value={formData.faculty_name}
              onChange={handleChange}
              required
            />
          </Form.Group>
          <br />
          <Button variant="primary" type="submit" onClick={handleSubmit} >
            Save Changes
          </Button>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={closeModal}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
    
  );
};

export default EditModal;
