import React, { useEffect, useState } from 'react';
import styles from "../../styles/reels.module.css";
import { Button, Table } from 'react-bootstrap';
import axios from '../../constents/axios';
import UploadReel from './UploadReel'; 

const Reels = () => {
  const [reels, setReels] = useState([]);
  const [showModal, setShowModal] = useState(false); 

  const fetchReels = async () => {
    try {
      const response = await axios.get('/getReels'); 
      setReels(response.data);
    } catch (error) {
      console.error("Error fetching reels:", error);
    }
  };

  useEffect(() => {
    fetchReels();
  }, []);

  const handleShow = () => setShowModal(true);

  const handleClose = () => {
    setShowModal(false);
    fetchReels();
  }

  return (
    <div className={styles.reelPage}>
      <div className={styles.reelHeadDiv}>
        <h1 className={styles.reelHead}>Reels</h1>
        <Button className={styles.addReelBtn} onClick={handleShow}>Add New Reel</Button>
      </div>
      <div className={styles.reelTable} style={{ maxHeight: '80vh', overflowY: 'auto' }}>
        <Table bordered hover>
          <thead>
            <tr>
              <th>#</th>
              <th>Reel path</th>
              <th>Outed Date</th>
              <th>Outed Time</th>
            </tr>
          </thead>
          <tbody>
  {reels.map((reel, index) => {
    const dateObj = new Date(reel.date);
    const formattedDate = dateObj.toLocaleDateString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    });
    const formattedTime = dateObj.toLocaleTimeString('en-GB', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true
    });
    return (
      <tr key={reel._id}>
        <td>{index + 1}</td>
        <td>{reel.reel}</td>
        <td>{formattedDate}</td>
        <td>{formattedTime}</td>
      </tr>
    );
  })}
</tbody>

        </Table>
      </div>

      <UploadReel show={showModal} handleClose={handleClose} onSuccess={fetchReels} />
    </div>
  );
}

export default Reels;
