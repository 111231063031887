import React from 'react';
import { Container, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import styles from '../styles/notFound.module.css'; 
import { FaExclamationTriangle } from 'react-icons/fa'; 

const NotFound = () => {
  return (
    <Container className={styles['not-found-container']}>
      <div className={styles['not-found-content']}>
        <FaExclamationTriangle className={styles['error-icon']} />
        <h1 className={styles['error-code']}>404</h1>
        <h2>Oops! Page Not Found</h2>
        <p>The page you are looking for doesn’t exist or has been moved.</p>
        <Link to="/">
          <Button variant="primary" className={styles['go-home-btn']}>Go Back Home</Button>
        </Link>
      </div>
    </Container>
  );
};

export default NotFound;
