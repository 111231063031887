import React, { useEffect, useState } from "react";
// import axios from "axios";
import axios from "../../constents/axios";
import "../../styles/addLiveVideo.module.css";
import SuccessModal from "./SuccessModal";

function AddLiveVideo() {
  const [show, setShow] = useState(false);
  const [errors, setErrors] = useState({});
  const [subject, setSubject] = useState([]);
  const [LiveData, setLiveData] = useState({
    subject: '',
    topic: '',
    subtopic: '',
    caption: '',
    classroom_link: '',
    faculty_name: '',
    description: ''
  }); 

  

  useEffect(() => {
    // Fetch subjects from server when component mounts
    axios.get('/subjects')
      .then(response => {
        setSubject(response.data);
      })
      .catch(error => {
        console.error('Error fetching subjects:', error);
      });
  }, []);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setLiveData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };
  const handleUpload = async (e) => {
    e.preventDefault();
    try {
      await axios.post('/UploadLiveVideo', LiveData);
      // Reset form after successful submission
      setLiveData({
        subject: '',
        caption: '',
        topic: '',
        subtopic: '',
        classroom_link: '',
        faculty_name: '',
        description: ''
      });
      // alert('Form submitted successfully!');
      setShow(true);
    } catch (err) {
      console.log("sjhgf");
      console.error('Error uploading data:', err);
      alert('Oops!Something went wrong !!!');
    }
  };
  return (
    <div className="app pb-2">
      <div className="mainHeading">
        <h4><b>CREATE LIVE VIDEO</b></h4>
      </div>
      <form onSubmit={handleUpload} className="form">
      <div className="row">
        <div className="col-md-6">
        <div className="form-group">
        <div className="row">
        <div className="col-md-12">
          <div className="form-group">
            <label>Subject:</label>
            <select className="form-control" name="subject" value={LiveData.subject}  onChange={handleChange} required>
            <option value=''>Select Subject</option>
          {subject.map((subjects, index) => (
            <option key={index} value={subjects}>{subjects}</option>
          ))}
            </select>
        {errors.subject && <span>{errors.subject}</span>}
          </div>
          </div>
        </div>

        <div className="row pt-2">
        <div className="col-md-12">
          <div className="form-group">
            <label>Caption:</label>
            <input type="text" className="form-control" name="caption" value={LiveData.caption} onChange={handleChange} placeholder="Enter Caption" required />
        {errors.caption && <span>{errors.caption}</span>}
          </div>
          </div>
          </div>
        
          <div className="row pt-2">
        <div className="col-md-12">
          <div className="form-group">
            <label>Topic:</label>
            <input type="text" className="form-control" name="topic" value={LiveData.topic} onChange={handleChange} placeholder="Enter Topic" required />
        {errors.topic && <span>{errors.topic}</span>}
          </div>
          </div>
          </div>

          <div className="row pt-2">
        <div className="col-md-12">
          <div className="form-group">
            <label>Subtopic:</label>
            <input type="text" className="form-control" name="subtopic" value={LiveData.subtopic} onChange={handleChange} placeholder="Enter Subtopic" required/>
            {errors.subtopic && <span>{errors.subtopic}</span>}
          </div>
          </div>
          </div>


          <div className="row pt-2">
        <div className="col-md-12">
          <div className="form-group">
            <label>Classroom Link:</label>
            <input type="text" className="form-control" name="classroom_link" value={LiveData.classroom_link} onChange={handleChange} placeholder="Enter Classroom Link" required />
            {errors.classroom_link && <span>{errors.classroom_link}</span>}
          </div>
          </div>
          </div>
          </div>
          </div>

      <div className="col-md-6 form">
      <div className="form-group">
          <div className="row">
          <div className="col-md-12">
              <div className="form-group">
                <label>Faculty Name:</label>
                <input type="text" className="form-control" name="faculty_name" value={LiveData.faculty_name} onChange={handleChange} placeholder="Enter Faculty Name" required/>
                {errors.faculty_name && <span>{errors.faculty_name}</span>}
              </div>
              </div>
              </div>
        

            <div className="row pt-2">
            <div className="col-md-12">
            <div className="form-group">
              <label>Description:</label>
              <textarea className="form-control" name="description" value={LiveData.description} onChange={handleChange} rows="5" required></textarea>
              {errors.description && <span>{errors.description}</span>}
            </div>
            </div>
            </div>
        </div>
        </div>
        
      <div className="row pt-3">
        <div className="col-md-12">
          <div className="form-group">
            <button className="btn btn-primary" type="submit" >
              Upload
            </button>
          </div>
        </div>
      </div>
      <SuccessModal show={show} handleClose={() => setShow(false)} message="Video Details Added succesfully..!"/>
      </div>
      </form>
    </div>

  );
}

export default AddLiveVideo;
