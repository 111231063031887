import React, { useEffect, useState } from 'react';
import { Button, Modal, Form, Alert } from 'react-bootstrap';
import Select from 'react-select';
import { MdArrowBack, MdOutlineAddBox } from 'react-icons/md';
import axios from '../../../../constents/axios';

const AddTopics = ({ show, onHide, courseName, onSuccess }) => {
    const [topicName, setTopicName] = useState('');
    const [isCreatingNew, setIsCreatingNew] = useState(false);
    const [topics, setTopics] = useState([]);
    const [menuIsOpen, setMenuIsOpen] = useState(false); 
    const [errorMessage, setErrorMessage] = useState(null);

    const fetchTopics = async () => {
        try {
            const response = await axios.get(`/get-topics/${courseName}`);
            setTopics(response.data?.data || []); 
        } catch (error) {
            console.error('Error fetching topics:', error);
        }
    };

    const handleAddNewTopic = async () => {
        try {
            const newTopic = topicName;
            await axios.post(`/add-newTopic`, {
                course_id: `${courseName}`,
                topics: newTopic,
                subtopic: "",
            });
            fetchTopics();
            setIsCreatingNew(false); 
            setMenuIsOpen(true); 
            setTopicName(''); 
            onSuccess(); 
        } catch (error) {
            console.error('Error adding new topic:', error);
        }
    };

    const handleAddTopic = async () => {
        try {
            await axios.post(`/add-topic`, {
                id: 0,
                course_id: `${courseName}`,
                topics: `${topicName}`,
                subtopic: "?",
                material_type: "?",
                material_id: "?",
                priority: 0
            });
            onSuccess(); 
            onHide(); 
        } catch (error) {
            console.error('Error adding topic:', error);
            if (error.response && error.response.status === 409) {
                setErrorMessage("Subtopic already exists. Please use a different name.");
              } else {
                // Handle other errors (e.g., network issues, server errors)
                setErrorMessage("An error occurred while adding the subtopic. Please try again.");
              }
              setTimeout(() => {
                setErrorMessage("");
              }, 3000);
        }
    };

    const handleSelectChange = (selectedOption) => {
        if (selectedOption.value === 'create-new') {
            setIsCreatingNew(true);
        } else {
            setTopicName(selectedOption.value);
            setIsCreatingNew(false);
        }
    };

    const handleBackToSelect = () => {
        setIsCreatingNew(false);
        setTopicName('');
    };

    useEffect(() => {
        fetchTopics();
    }, []);

    const options = [
        { value: 'create-new', label: <><MdOutlineAddBox /> Create New Topic</> }, 
        ...(topics?.map((t) => ({ value: t.topic, label: t.topic })) || []), 
    ];

    return (
        <Modal show={show} size="lg" backdrop="static" onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">Add Topic</Modal.Title>
            </Modal.Header>
            <Form>
                <Modal.Body>
                {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
                    {isCreatingNew ? (
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <MdArrowBack
                                style={{ cursor: 'pointer', marginRight: '10px' }}
                                size={24}
                                onClick={handleBackToSelect}
                            />
                            <Form.Control
                                type="text"
                                placeholder="Enter new topic name"
                                value={topicName}
                                onChange={(e) => setTopicName(e.target.value)}
                            />
                        </div>
                    ) : (
                        <Select
                            options={options}
                            onChange={handleSelectChange}
                            placeholder="Select a Topic"
                            onMenuOpen={() => setMenuIsOpen(true)}
                            menuIsOpen={menuIsOpen} 
                            onMenuClose={() => setMenuIsOpen(false)} 
                        />
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" disabled={!topicName.trim()} onClick={isCreatingNew ? handleAddNewTopic : handleAddTopic}>
                        {isCreatingNew ? 'Add New Topic' : 'Add Topic'}
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
};

export default AddTopics;
