import React, { useEffect, useState } from 'react';
import styles from "../../styles/courses.module.css";
import axios from '../../constents/axios';
import { useNavigate } from 'react-router-dom';
import notFound from '../not-found.gif';
import { Modal, Button } from 'react-bootstrap';

const Courses = () => {
    const [data, setData] = useState([]);
    const [course, setCourse] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [modalSearchQuery, setModalSearchQuery] = useState("");
    const [labelSearchQuery, setLabelSearchQuery] = useState(""); // New state for label search query
    const [selectedLabel, setSelectedLabel] = useState(""); 
    const [showModal, setShowModal] = useState(false);
    const navigate = useNavigate();

    const handleModalSearch = (e) => {
        setModalSearchQuery(e.target.value.toLowerCase());
    };

    const handleLabelSearch = (e) => {
        setLabelSearchQuery(e.target.value.toLowerCase());
    };

    const filteredLabels = data.filter(label => 
        label.label.toLowerCase().includes(labelSearchQuery)
    );

    const filteredCourses = course.filter(c =>
        c.title?.toLowerCase().includes(modalSearchQuery)
    );

    const handleLabelClick = (label) => {
        setSelectedLabel(label.label);
        setShowModal(true);
    };

    const handleCourseClick = (course) => {
        navigate(`/studentJourney/${course.course_id}`);
        localStorage.setItem('CourseTitle', course.title);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setModalSearchQuery("");
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get('/fetch-label', {
                    headers: {
                        'autherisation': `--adminpanel--apikey--`,
                    }
                });
                setData(response.data.data);
                setLoading(false);
            } catch (error) {
                console.error("There was an error making the request:", error);
                setError(error.response ? error.response.data : error.message);
                setLoading(false);
            }
        };
        if (selectedLabel) {
            const fetchCourse = async () => {
                try {
                    const response = await axios.get(`/courseId/${selectedLabel}`, {
                        headers: {
                            'autherisation': `--adminpanel--apikey--`,
                        }
                    });
                    setCourse(response.data.data);
                    setLoading(false);
                } catch (error) {
                    console.error("There was an error making the request:", error);
                    setError(error.response ? error.response.data : error.message);
                    setLoading(false);
                }
            };

            fetchCourse();
        }
        fetchData();
    }, [selectedLabel]);

    return (
        <div className={styles.coursesContainer}>
            <h1><strong>Select Label</strong></h1>
            <br />
            <br />
            <input
                type="text"
                placeholder="Search Labels..."
                className={styles.searchInput}
                value={labelSearchQuery}
                onChange={handleLabelSearch}
                style={{ marginBottom: '1rem', width: '100%' }}
            />
            {loading ? (
                <lord-icon
                    src="https://cdn.lordicon.com/jpgpblwn.json"
                    trigger="loop"
                    state="loop-transparency"
                    style={{width:"250px", height:"250px"}}
                ></lord-icon>
            ) : error ? (
                <p>{error}</p>
            ) : (
                <div className={styles.courseList}>
                    <div className='row'>
                        {filteredLabels.length > 0 ? (
                            filteredLabels.map((label, index) => (
                                <div key={index} className='col-md-6'>
                                    <div
                                        className={styles.courseItem}
                                        onClick={() => handleLabelClick(label)}
                                    >
                                        <h3>{label.label}</h3>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <p className={styles.noResults}><img src={notFound} width={"200px"} height={"200px"} alt="" /></p>
                        )}
                    </div>
                </div>
            )}

            <Modal show={showModal} onHide={handleCloseModal} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Courses for {selectedLabel}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <input
                        type="text"
                        placeholder="Search Courses..."
                        className={styles.searchInput}
                        value={modalSearchQuery}
                        onChange={handleModalSearch}
                        style={{ marginBottom: '1rem' }}
                    />
                    {loading ? (
                        <p>Loading...</p>
                    ) : error ? (
                        <p>{error}</p>
                    ) : filteredCourses.length > 0 ? (
                        filteredCourses.map((course, index) => (
                            <div key={index} onClick={() => handleCourseClick(course)} className={styles.courseDetailItem}>
                                <h4>{course.title}</h4>
                            </div>
                        ))
                    ) : (
                        <p>No courses available for the selected label.</p>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default Courses;
