import axios from 'axios'

// const URL = "http://localhost:3002"

const URL = "https://adminnode.aifer.in/"

const instance = axios.create({
    baseURL: URL,
   
  });
  export default instance   