import React, { useEffect, useState } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import axios from '../../../../constents/axios'
import Select from 'react-select';
import { MdArrowBack, MdOutlineAddBox } from 'react-icons/md';

const EditSubTopics = ({ show, onHide, courseName, currentTopicName, currentSubTopic, onSuccess }) => {
  const [subTopicName, setSubTopicName] = useState(currentSubTopic);
  const [isCreatingNew, setIsCreatingNew] = useState(false);
  const [subTopics, setSubTopics] = useState([]);
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [error, setError] = useState(null);


  const fetchSubTopics = async () => {
    try {
        const response = await axios.post(`/get-subtopics`, {
            course_id: `${courseName}`,
            topic: currentTopicName
        });
        setSubTopics(response.data?.data || []); 
    } catch (error) {
        console.error('Error fetching subtopics:', error);
        setError('Failed to fetch subtopics. Please try again later.');
    }
};

const handleAddNewTopic = async () => {
  if (!subTopicName.trim()) return; // prevent adding empty subtopics

  try {
      const newSubTopic = subTopicName.trim();
      await axios.post(`/add-newSubTopic`, {
          course_id: `${courseName}`,
          topics: currentTopicName,
          subtopic: newSubTopic,
      });
      fetchSubTopics(); 
      setIsCreatingNew(false); 
      setMenuIsOpen(true); 
      setSubTopicName(''); 
      onSuccess(); 
  } catch (error) {
      console.error('Error adding new topic:', error);
      setError('Failed to add new subtopic. Please try again.');
  }
};

const handleSelectChange = (selectedOption) => {
  if (selectedOption.value === 'create-new') {
      setIsCreatingNew(true);
  } else {
      setSubTopicName(selectedOption.value);
      setIsCreatingNew(false);
  }
};

  const handleEditSubTopic = async () => {
    if (!subTopicName.trim()) {
      setError('Topic name cannot be empty');
      setTimeout(() => {
        setError(null);
      }, 3000);
      return;
    }
  
    try {
      const response = await axios.put(`/edit-subtopic`, 
        { 
          course_id: courseName, 
          currentTopic: currentTopicName, 
          currentSubTopic: currentSubTopic,
          newSubTopic: subTopicName
        });
  
      if (response.status === 200) {
        onSuccess();
        onHide(); 
      } else {
        setError('Failed to edit topic');
      }
    } catch (error) {
      console.error('Error editing topic:', error);
      setError('Error editing topic');
    }
  };
  
  const handleBackToSelect = () => {
    setIsCreatingNew(false);
    setSubTopicName('');
};
  
  // const handleSubTopicNameChange = (e) => {
  //   e.preventDefault();
  //   setSubTopicName(e.target.value);
  // };

  useEffect(() => {
    fetchSubTopics();
    setSubTopicName(currentSubTopic);
  }, [currentSubTopic, courseName, currentTopicName]);

  const options = [
    { value: 'create-new', label: <><MdOutlineAddBox /> Create New Topic</> },
    ...(subTopics?.filter((sub) => sub.subtopic && sub.subtopic.trim() !== "")
        .map((sub) => ({ value: sub.subtopic, label: sub.subtopic })) || []),
];

  return (
    <Modal show={show} size="lg" backdrop="static" onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">Edit SubTopics</Modal.Title>
      </Modal.Header>
      <Form >
      <Modal.Body>
      {isCreatingNew ? (
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <MdArrowBack
                                style={{ cursor: 'pointer', marginRight: '10px' }}
                                size={24}
                                onClick={handleBackToSelect}
                            />
                            <Form.Control
                                size="lg"
                                type="text"
                                placeholder="Enter the SubTopic Name"
                                value={subTopicName}
                                onChange={(e) => setSubTopicName(e.target.value)}
                                required
                            />
                        </div>
                    ) : (
                        <Select
                            options={options}
                            onChange={handleSelectChange}
                            placeholder="Select a Topic"
                            onMenuOpen={() => setMenuIsOpen(true)}
                            menuIsOpen={menuIsOpen}
                            onMenuClose={() => setMenuIsOpen(false)}
                        />
                    )}
      {/* <Form.Control size="lg" type="text" placeholder="Edit SubTopics" required value={subTopicName} onChange={handleSubTopicNameChange} /> */}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" disabled={!subTopicName.trim()} onClick={isCreatingNew ? handleAddNewTopic : handleEditSubTopic}>
        {isCreatingNew ? 'Add New SubTopic' : 'Edit SubTopic'}
          </Button>
      </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default EditSubTopics;
